<script setup lang="ts">
import type { Category } from '@shopware-pwa/types';
import { getTranslatedProperty } from '@shopware-pwa/helpers-next';

const props = defineProps<{ element: Category }>();
const isSocialNavigation = props.element.name?.toLowerCase() === 'folge uns';
</script>
<template>
  <div class="flex flex-col gap-8">
    <p class="text-white">
      {{ getTranslatedProperty(element, 'name') }}
    </p>
    <div class="flex gap-8" :class="{ 'flex-col': !isSocialNavigation }">
      <NavigationLink
        v-for="navigationChild in element.children"
        :key="navigationChild.id"
        :category="navigationChild"
        :is-social-navigation="isSocialNavigation"
      />
    </div>
  </div>
</template>
